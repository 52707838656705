hideSensitiveInfoModule = (function () {

    var MaskType = {
        Salary: '$ . . . . . .',
        Date: 'MM/DD/YYYY',
        SSN: 'XXX-XX-XXXX'
    };

    var lockObject = false;
    var timerMillis = 250;

    var maskAllContent = function () {

        if (lockObject) {
            return;
        }

        $('*[data-hide-sensitive-info]').each(function (i, e) {

            var hiddenData = $(this).data('hide-sensitive-info');

            if (!hiddenData) {
                return;
            }

            var mask = $(this).data('mask') || MaskType[$(this).data('mask-type')];
            $(e).text(mask);
            $(e).append('&nbsp;<i class="far fa-eye"></i>');
        });
    }

    return {
        MaskAllContent: function () {
            maskAllContent();
        },
        Init: function () {
            maskAllContent();           
 
            $(document.body).on('mousedown touchstart', '*[data-hide-sensitive-info]', (function () {

                lockObject = true;
                
                var hiddenData = $(this).data('hide-sensitive-info');

                if (!hiddenData) {
                    return;
                }

                var cleanVal = $(this).data('hide-sensitive-info');
                $(this).text(cleanVal);
                $(this).append('&nbsp;<i class="far fa-eye-slash light-gray-text"></i>');
            }));

            $(document.body).on('mouseup touchend', (function () {
                lockObject = false;
                maskAllContent();
            }));
        }
    }
})();