(function () {
    // maps to https://developers.google.com/analytics/devguides/collection/analyticsjs/field-reference#create
    GoogleAnalyticsClientContainer = function (trackingId, cookieDomain, trackerName, fieldsObject) {
        this.TrackingId = trackingId;
        this.CookieDomain = cookieDomain || "auto";
        this.TrackerName = trackerName;
        this.FieldsObject = fieldsObject;
    }
})();

var googleAnalyticsModule = (function () {
    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
		m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g;
        m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

    var GetCaseName = function () {
        var caseName = document.cookie.replace(/(?:(?:^|.*;\s*)Case\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        return caseName;
    }

    var GetParticipantID = function () {
        var partIdEntry = document.cookie
            .split("; ")
            .filter(function (x) {
                return x.lastIndexOf("partIdGA", 0) === 0;
            }); 

        if (!partIdEntry || partIdEntry.length == 0) {
            return "0";
        }

        var partIdValue = partIdEntry[0].split("=")

        return partIdValue && partIdValue.length > 1 && partIdValue[1] || "0";
    }

    var SendEvent = function (category, action, label, value) {
        var params = {
            transport: 'beacon',
            eventCategory: category,
            eventAction: action,
            eventLabel: label,
            eventValue: value
        };

        var caseName = GetCaseName();
        if (caseName) {
            params.eventCategory = caseName + '/' + params.eventCategory;
        }

        ga(function () {
            var trackers = ga.getAll();
            trackers.forEach(function (tracker) {

                var partID = GetParticipantID();
                if (partID) {
                    tracker.set('dimension1', partID); // participantId
                }

                var caseName = GetCaseName();
                if (caseName) {
                    tracker.set('dimension2', caseName); // caseName
                }

                tracker.send('event', params);
            });
        });
    };

    var collectData = function (jqueryElement) {

        var isTracked = jqueryElement.data('ga-tracked');
        if (!isTracked || new Date() - isTracked > 300) {
            jqueryElement.data('ga-tracked', new Date());

            var myCategory = jqueryElement.data('gaEventCategory') || 'outbound';
            var myAction = jqueryElement.data('gaEventAction') || 'click';
            var myLabel = jqueryElement.data('gaEventLabel') || jqueryElement.attr('href') || jqueryElement.text().trim();
            var myValue = jqueryElement.data('gaEventValue');

            if (jqueryElement.prop('tagName').toLowerCase() == "tt-planchoicebtn") {
                myLabel = jqueryElement.data('gaEventLabel') + '/' + (jqueryElement.attr('href') || jqueryElement.text().trim());
            }

            // doing this for voluntary benefits. if there is no category on the tt-planchoicebtn directive then dont log the event.
            if (!jqueryElement.data('gaEventCategory') && jqueryElement.prop('tagName').toLowerCase() == "tt-planchoicebtn") {
                return;
            }

            SendEvent(myCategory, myAction, myLabel, myValue);
        }
    };

    return {
        Init: function (googleAnalyticsClientContainerArray) {
            if (!googleAnalyticsClientContainerArray || googleAnalyticsClientContainerArray.length == 0) {
                return;
            }

            $(window).on('load', function () {

                // Create
                $(googleAnalyticsClientContainerArray).each(function (i, e) {

                    if (!e.TrackingId) {
                        return;
                    }

                    var params = {
                        trackingId: e.TrackingId,
                        cookieDomain: e.CookieDomain || 'auto'
                    };

                    var userId = GetParticipantID();
                    if (userId) {
                        params.userId = userId;
                    }

                    if (e.TrackerName) {
                        params.name = e.TrackerName;
                    }

                    ga("create", params);
                });

                // SendPageView
                ga(function () {
                    var trackers = ga.getAll();
                    trackers.forEach(function (tracker) {

                        var partID = GetParticipantID();
                        if (partID) {
                            tracker.set('dimension1', partID); // participantId
                        }

                        var caseName = GetCaseName();
                        if (caseName) {
                            tracker.set('dimension2', caseName); // caseName
                        }

                        tracker.send("pageview");
                    });
                });

                // SendPageLoad
                if (Modernizr.performance) {
                    var timeSincePageLoad = Math.round(performance.now());

                    ga(function () {
                        var trackers = ga.getAll();
                        trackers.forEach(function (tracker) {                           
                            tracker.send("timing", "Page Load", timeSincePageLoad);
                        });
                    });
                }

                // used on popups of Available programs to capture the active available program shown.
                // could also be used on other modal popups we track.
                $('.modal').on('shown.bs.modal', function (e) {
                    var activeChapter = $(e.target).find('.active[data-ga-event-category]');

                    if (activeChapter && activeChapter.length == 1) {
                        collectData(activeChapter);
                    }
                });

                // Event Tracking
                $(document.body).on('click', "a[href^='http'], tt-planchoicebtn, div[data-ga-event-category], li[data-ga-event-category]", function () {
                    // track analytics
                    collectData($(this));  
              

                    $.ajax({
                        type: "POST",
                        contentType: "application/json", 
                        url: "/Logging/LoggingTask/",
                        data: JSON.stringify({ Url:  $(this).attr('href') })
                    });
                }); 
            });
        }
    }
})();